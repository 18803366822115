<template>
  <el-container>
    <div id="feedback">
      <el-tooltip class="item" effect="dark" content="Feedback" placement="top">
        <span class="contact-help text-warning" @click="goToFeedbackPage">
          <i class="fas fa-bug"></i>
        </span>
      </el-tooltip>
    </div>
    <el-header v-if="showHeader">
      <TOEFLHeader :theme="theme"/>
    </el-header>
    <el-main :key="`${$route.fullPath}Page`" :class="isIpad ? 'ipad' : ''">
      <GlobalNotification :key="$route.fullPath" />
      <div v-if="existAdBlock" class="container">
        <PageTitle>
          {{ getSimpleTitle(this.$route.name) }}
        </PageTitle>
      </div>
      <router-view v-else />
    </el-main>
    <Footer :theme="theme" v-if="showFooter" />
    <el-backtop class="phone" :right="15" :bottom="40"></el-backtop>
    <el-backtop class="pc" :right="40" :bottom="100"></el-backtop>
    <template v-if="!isIgnoreAdPages && !isLocalhost && !isPro">
      <AdBlockDialog :key="$route.fullPath" @afterFound="foundAdBlockResult" />
    </template>
  </el-container>
</template>

<script>
import { user } from "@ivy-way/material";
/*eslint-disable no-unused-vars */
import { mapState, mapActions } from "vuex";
/*eslint-enable */
import Theme from "@/common/theme";
import PageTitle from "@/components/PageTitle.vue";
import Footer from "@/components/Footer";
import TOEFLHeader from "@/components/TOEFLHeader";
import GlobalNotification from "@/views/GlobalNotification";
import AdBlockDialog from "@/views/AdBlockDialog";

export default {
  metaInfo() {
    return {
      title: this.CompanyName
    };
  },
  components: {
    PageTitle,
    Footer,
    TOEFLHeader,
    AdBlockDialog,
    GlobalNotification
  },

  mixins: [],

  props: [],
  data() {
    return {
      countryCode: "",
      existAdBlock: false
    };
  },
  computed: {
    ...mapState("user", ["lang", "profile"]),
    theme() {
      return Theme;
    },
    isIpad() {
      let isIpad = false;
      if (navigator.userAgent.indexOf("iPad") != -1) {
        isIpad = true;
      }
      return isIpad;
    },
    ivyWayToken() {
      let token = 0;
      if (this.$route.query.token) {
        token = this.$route.query.token;
      }
      return token;
    },
    showLink() {
      let url = "https://blog.ivy-way.com/toefl-cn/";
      if (this.lang.value === "ZH-CN") {
        url = url + "?lang=cn";
      } else if (this.lang.value === "EN-US") {
        url = url + "?lang=en";
      }
      return url;
    },
    showHeader() {
      if (this.$route.meta.showHeader === undefined) {
        return true;
      } else {
        return false;
      }
    },
    showFooter() {
      if (this.$route.meta.showFooter === undefined) {
        return true;
      } else {
        return false;
      }
    },
    isIgnoreAdPages() {
      const ignoreAdPages = ["ToeflTest", "MToeflTest", "ToeflPlans"];
      if (this.$route.name) {
        return ignoreAdPages.includes(this.$route.name);
      } else {
        const unwatch = this.$watch(
          () => this.$route,
          async route => {
            unwatch();
            return ignoreAdPages.includes(route);
          }
        );
      }
      return true;
    },
    isLocalhost() {
      return window.location.href.includes("http://localhost:");
    },
    isLogin() {
      return this.$store.getters["user/token"] !== "";
    },
    isPro() {
      return this.$store.getters["user/getIsPro"];
    }
  },
  watch: {
    ivyWayToken(val) {
      if (val && this.theme.name === "Ivy-Way") {
        this.handleIvyWayLogin({ token: val });
      }
    }
  },
  async created() {
    if (this.isLogin) {
      await this.$store.dispatch("user/getProfile");
      if (!this.isPro) {
        let googleAdScript = document.createElement("script");
        googleAdScript.setAttribute(
          "src",
          "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3412946985829139"
        );
        googleAdScript.setAttribute("crossorigin", "anonymous");
        document.head.appendChild(googleAdScript);
      }
    }
  },
  methods: {
    ...mapActions("user", ["ivyWayLogin"]),
    async handleIvyWayLogin(token) {
      await this.ivyWayLogin({
        token
      });
      await this.$message({
        message: this.$t("message.login_success"),
        type: "success"
      });
      await this.$store.dispatch("user/getProfile");
      await this.resetPageParam();
    },
    resetPageParam() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          token: null
        },
        params: {
          ...this.$route.params
        }
      });
    },
    async goToFeedbackPage() {
      window.open("mailto:support@testadmit.com", "_blank");
    },
    foundAdBlockResult(result) {
      this.existAdBlock = result;
    },
    getSimpleTitle(routeName) {
      if (routeName === "Profile") {
        return user.displayName(
          this.profile.first_name,
          this.profile.last_name
        );
      }

      const simpleTitleLocalKeyMap = {
        LandingPage: "toefl.index.title_new",
        ToeflTestResults: "toefl.index.title_new",
        ToeflPlans: "header.plans",
        Flashcards: "header.flashcards",
        MyFlashcards: "flashcards.myFlashCards",
        Lessons: "pageTitle.lessons",
        ToeflProfileBalance: "toefl.Balance",
        ToeflScorereport: "pageTitle.scoreReport",
        toeflResult: "pageTitle.ToeflTestResults",
        ResultPDF: "pageTitle.ToeflTestResults",
        Gradingsamples: "GradingSamples.TOEFL Writing Grading",
        ToeflResolve: "pageTitle.explanation"
      };
      return this.$t(
        simpleTitleLocalKeyMap[routeName] || simpleTitleLocalKeyMap.LandingPage
      );
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/style";

.el-header {
  box-shadow: 0 5px 5px #aaa;
  background-color: white;
  position: fixed;
  width: 100%;
  z-index: 1001;
  padding: 0;
  margin: 0;
}

header.el-header {
  padding: 0;
  margin: 0;
}
.pc {
  display: flex !important;
}
.phone {
  display: none !important;
}


main.el-main {
  min-height: calc(100vh - 74px);
  padding: 0;
  padding-top: 100px;
}

.container {
  padding-top: 40px;
}
@media screen and (max-width: 992px) {
  main.el-main {
    padding-top: 60px;
  }
  .container {
    padding-top: 20px;
  }
}

.toefl-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 25px;
}
.toefl-welcome {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
<style scoped>
::v-deep #feedback .el-dialog__body {
  padding: 5px 40px;
}
::v-deep #feedback .el-alert {
  padding: 0px 16px;
}

.contact-help {
  right: 40px;
  bottom: 160px;
  position: fixed;
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: var(--themeColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0 0 6px rgb(0 0 0 / 12%);
  cursor: pointer;
  z-index: 100;
}
::v-deep .el-alert .el-alert__description {
  margin-top: 0;
}
::v-deep .el-upload-dragger {
  width: 450px;
  height: 200px;
}
@media screen and (max-width: 768px) {
  .phone {
    display: flex !important;
  }
  .pc {
    display: none !important;
  }

  ::v-deep .el-dialog--center .el-dialog__body {
    padding: 5px 20px;
  }
  ::v-deep .el-form--label-left .el-form-item__label {
    display: block;
    width: 100%;
    line-height: normal;
  }
  ::v-deep .el-upload-dragger {
    width: 100%;
    height: 150px;
  }
  ::v-deep .el-upload-dragger .el-icon-upload {
    margin: 20px 0 16px;
  }
  ::v-deep .el-upload {
    width: 100%;
  }
  /* ::v-deep .el-form-item {
    margin-bottom: 12px;
  } */
  .contact-help {
    bottom: 100px;
    right: 15px;
  }
  ::v-deep #feedback .el-dialog__body {
    padding: 5px 15px;
  }
  ::v-deep.el-button + .el-button {
    margin-left: 0;
  }
}
</style>
